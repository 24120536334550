import { AppLogos } from "~/layout/components/AppLogos";

export default function Footer() {
  let currentYear = new Date().getFullYear();

  return (
    <footer className="footer footer-center bg-blue-50 px-10 py-14 gap-8">
      <div className="flex flex-wrap justify-center md:justify-between flex-col lg:flex-row max-w-7xl w-full">
        <AppLogos className="" />
        <ul className="menu menu-vertical md:menu-horizontal font-bold flex-shrink-0">
          <li>
            <a
              href="https://www.dotadda.io/acceptable-use-policy/"
              target="_blank"
            >
              Acceptable Use
            </a>
          </li>
          <li>
            <a href="https://www.dotadda.io/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="https://www.dotadda.io/terms-and-conditions/"
              target="_blank"
            >
              Terms and Conditions
            </a>
          </li>
          <li>
            <a
              href="https://www.dotadda.io/terms-and-conditions/"
              target="_blank"
            >
              Cookie Policy
            </a>
          </li>
        </ul>
      </div>
      <hr className="border-1/2 border-blue-300 w-full max-w-7xl" />
      <div className="flex gap-6 flex-col md:flex-row md:gap-20 text-md max-w-7xl">
        <div>
          <strong className="text-lg">Corporate Office</strong>
          <br />
          56 Bellaire Court
          <br />
          Appleton, WI 54911
        </div>
        <div>
          <strong className="text-lg">New York Sales Office</strong>
          <br />
          714 Broadway, Suite 5<br /> New York, NY 10003
        </div>
        <div>
          <div className="mb-2 text-md">
            <strong>Phone: </strong>
            <a href="tel:19202210096" className="link link-primary">
              (920) 221-0096
            </a>
          </div>
          <a
            href="https://us5.list-manage.com/contact-form?u=337acaff6a130f2cfbbcbb76c&form_id=433bdee2c4f547a7b284411536ff1a68"
            target="_blank"
            className="btn btn-sm btn-primary normal-case btn-block"
            role="button"
          >
            Contact Us
          </a>
        </div>
      </div>
      <hr className="border-1/2 border-blue-300 w-full max-w-7xl" />
      <div>© {currentYear} DoTadda. All rights reserved.</div>
    </footer>
  );
}
