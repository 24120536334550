import { ActionFunctionArgs, redirect } from "@remix-run/node";
import { Form, useSearchParams } from "@remix-run/react";
import { useEffect, useState } from "react";
import { Logo } from "~/layout/components/Logo";
import { AppErrorBoundary } from "~/toolkit/components/errors/AppErrorBoundary";
import { LoadingLogo } from "~/toolkit/components/loaders/LoadingLogo";
import { cn } from "~/toolkit/components/utils";
import { getAuthRedirectUri } from "~/toolkit/http/url.utils";
import { authProvider } from "../auth.remix.server";
import { HomePageAppHeader } from "~/layout/components/HomePageAppHeader";
import Footer from "~/features/marketing/components/Footer";

export const LOGIN_ROUTE = "auth/routes/login.route.tsx";

export const action = async ({ request }: ActionFunctionArgs) => {
  let formData = await request.formData();

  let returnTo = (formData.get("returnTo") || new URL(request.url).origin) + "";
  if (returnTo.startsWith("/")) {
    returnTo = new URL(returnTo, new URL(request.url).origin).href;
  }
  const redirect_uri = getAuthRedirectUri(returnTo, "/api/auth-callback");
  console.log("🚀 | action | redirect_uri:", redirect_uri);

  let externalLoginUrl = await authProvider.getLoginUrl(
    redirect_uri,
    returnTo,
    {
      screen_hint: formData.has("intent")
        ? formData.get("intent") + ""
        : "login",
    }
  );
  console.log("🚀 | action | externalLoginUrl:", externalLoginUrl);
  return redirect(externalLoginUrl);
};

export function LoginButton({
  className = "btn-primary",
  intent = "login",
  children = "Log in",
  returnToPath = "",
}: {
  className?: string;
  intent?: "login" | "signup";
  children?: React.ReactNode;
  returnToPath?: string;
}) {
  let [searchParams] = useSearchParams();
  let [returnTo, setReturnTo] = useState<string>(
    searchParams.get("returnTo") || returnToPath || ""
  );

  let shouldAutoLogin = searchParams.get("autoLogin") === "true";

  useEffect(() => {
    setReturnTo((prev) => {
      if (prev.startsWith("/")) {
        // @ts-ignore
        return new URL(prev, window.location.origin).href;
      } else return prev;
    });
  }, [searchParams]);

  useEffect(() => {
    if (shouldAutoLogin) {
      setTimeout(() => {}, 100);
      // @ts-ignore
      let form = document.querySelector("form");
      if (form) {
        form.submit();
      }
    }
  }, [shouldAutoLogin]);
  return (
    <div>
      <Form
        method="post"
        action="/login"
        className={cn(shouldAutoLogin ? "opacity-0 h-4" : "")}
      >
        <input type="hidden" name="returnTo" value={returnTo}></input>
        <input type="hidden" name="intent" value={intent} />
        <button className={`btn ${className}`}>{children}</button>
      </Form>
      {shouldAutoLogin && <LoadingLogo Logo={Logo} />}
    </div>
  );
}

export default function LoginRoute() {
  let [searchParams] = useSearchParams();
  let shouldAutoLogin = searchParams.get("autoLogin") === "true";

  return (
    <>
      <div className="grid h-full grid-rows-[auto_1fr]">
        <HomePageAppHeader />
        <main className="relative top-16">
          <div className="px-6 pt-10 pb-24 mx-auto max-w-[1800px] sm:pb-32 lg:flex lg:px-8 lg:py-24">
            <div className="flex flex-1 min-h-full">
              <div className="flex flex-col justify-start flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 lg:pt-24">
                <div className="w-full max-w-sm mx-auto lg:w-96">
                  <div>
                    <p className="text-base font-semibold leading-7 text-gray-500">
                      Find your EDGE
                    </p>
                    <h2
                      id="features"
                      className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl flex items-center gap-2"
                    >
                      <Logo className="w-8 h-8 inline-block text-primary" />{" "}
                      <span>
                        Knowl<span className="text-primary-focus">edge</span>
                      </span>
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                      There's a firehose of information out there. Who can drink
                      in the most?
                    </p>
                  </div>

                  <div className="mt-10">
                    <div className="mt-10">
                      <div className="relative">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-200" />
                        </div>

                        <div className="relative flex justify-center text-sm font-medium leading-6">
                          <span className="px-6 text-gray-900 bg-white">
                            {shouldAutoLogin
                              ? "Updating your account"
                              : "Sign in to continue"}
                          </span>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 gap-4 mt-6 place-items-center">
                        <LoginButton intent="login" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative flex-1 hidden w-0 lg:block lg:pr-8 ">
                <div className="sm:px-6 lg:px-0 lg:mt-32">
                  <div className="relative px-6 pt-6 overflow-hidden bg-primary/40 isolate sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                    <div
                      className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-accent/50 opacity-20 ring-1 ring-inset ring-white"
                      aria-hidden="true"
                    />
                    <div className="max-w-2xl mx-auto sm:mx-0 sm:max-w-none">
                      <video
                        autoPlay
                        muted
                        playsInline
                        loop
                        className="w-full rounded-xl lg:rounded-2xl"
                        src="https://public-files.droopy.dev/dotadda-knowledge-hero-02.mp4"
                      />
                    </div>
                    <div
                      className="absolute inset-0 pointer-events-none ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export const ErrorBoundary = AppErrorBoundary;
