import { Link, useLocation } from "@remix-run/react";
import { LoginButton } from "~/auth/routes/login.route";
import { useCurrentUser } from "~/auth/useCurrentUser";
import { cn } from "~/toolkit/components/utils";
import { AccountDropdown } from "./AccountDropdown";
import { AppLogos } from "./AppLogos";

export function HomePageAppHeader({}) {
  let location = useLocation();
  const currentUser = useCurrentUser();
  return (
    <header className="w-full px-3 border-gray-200 sm:px-6 navbar bg-base-100 border-b fixed top-0 z-10">
      <div className="navbar-start">
        <AppLogos className="relative mr-4 sm:mr-10" />
        {/* <div className="divider divider-horizontal" /> */}
        <Link
          to="#pricing"
          className="btn btn-ghost hidden sm:flex normal-case"
        >
          Pricing
        </Link>
      </div>

      <div className="items-center gap-3 navbar-end">
        {currentUser ? (
          <>
            <Link
              to="/sessions/new"
              className={cn(
                "btn btn-secondary normal-case hidden sm:flex",
                location.pathname === "/sessions/new" ? "opacity-0" : ""
              )}
            >
              Start New
            </Link>
            <AccountDropdown user={currentUser} />
          </>
        ) : (
          <>
            <LoginButton
              className="btn-ghost normal-case hidden sm:block"
              returnToPath="/sessions/new"
            />
            <LoginButton
              intent="signup"
              className="btn-secondary normal-case hidden sm:block"
              returnToPath="/sessions/new"
            >
              Create a free account!
            </LoginButton>
          </>
        )}
        {/* SCREENS SMALLER THAN 640px */}
        <div className="dropdown dropdown-end sm:hidden">
          <div tabIndex={0} role="button" className="btn btn-ghost sm:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
          >
            <Link to="#pricing" className="btn btn-ghost normal-case">
              Pricing
            </Link>
            <div className="divider" />
            {currentUser ? (
              <>
                <Link
                  to="/sessions/new"
                  className={cn(
                    "btn btn-secondary btn-block normal-case mb-2",
                    location.pathname === "/sessions/new" ? "opacity-0" : ""
                  )}
                >
                  Start New
                </Link>
                {/* <li>
                  <AccountDropdown user={currentUser} />
                </li> */}
              </>
            ) : (
              <>
                <LoginButton
                  className="btn-ghost normal-case mb-2 btn-block"
                  returnToPath="/sessions/new"
                />
                <LoginButton
                  intent="signup"
                  className="btn-secondary btn-block normal-case"
                  returnToPath="/sessions/new"
                >
                  Create a free account!
                </LoginButton>
              </>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
}
